.pulse-animation {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.02);
  }
  60% {
    transform: scale(1.02);
  }
  90% {
    transform: scale(1.001);
  }
  100% {
    transform: scale(1);
  }
}
