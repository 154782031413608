@import url("./animations.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .button {
    @apply p-4 w-full max-w-3xl text-lg md:text-2xl cursor-pointer text-white bg-green-600 inline-block uppercase rounded-lg hover:bg-green-700 transition-all duration-200;
  }
}

.regular-shadow {
  box-shadow: 2px 4px 10px rgb(46, 119, 46);
}
